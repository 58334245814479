import React, { JSX } from "react";
import { MenuProps, Grid } from "antd";
import styles from "./styles.module.scss";
import CustomBarChart from "../WidgetComponents/BarChart";
import {
	GridContextProvider,
	GridDropZone,
	GridItem,
	swap,
	move,
} from "react-grid-dnd";

type WidgetGridProps = {
	img?: React.ReactNode | JSX.Element;
	name: string;
	role: string;
	items: MenuProps["items"];
};

export type GridItemType = {
	position: number;
	name: string;
	div?: React.ReactNode;
};

export type ItemsType = {
	[key: string]: GridItemType[];
};

const { useBreakpoint } = Grid;
const data = [
	{
		day: "ПН",
		bars: [
			{ label: "Факт", value: 50, color: "#E67E22" },
			{ label: "Норма", value: 30, color: "#2ECC71" },
			{ label: "Превышение", value: 20, color: "#8E44AD" },
		],
	},
	{
		day: "ВТ",
		bars: [
			{ label: "Факт", value: 40, color: "#8E44AD" },
			{ label: "Норма", value: 35, color: "#2ECC71" },
		],
	},
	{
		day: "СР",
		bars: [
			{ label: "Факт", value: 45, color: "#8E44AD" },
			{ label: "Норма", value: 40, color: "#2ECC71" },
		],
	},
	{
		day: "ЧТ",
		bars: [
			{ label: "Факт", value: 55, color: "#8E44AD" },
			{ label: "Норма", value: 25, color: "#2ECC71" },
			{ label: "Превышение", value: 10, color: "#E67E22" },
		],
	},
	{
		day: "ПТ",
		bars: [
			{ label: "Факт", value: 60, color: "#8E44AD" },
			{ label: "Норма", value: 30, color: "#2ECC71" },
		],
	},
	{
		day: "СБ",
		bars: [
			{ label: "Факт", value: 40, color: "#8E44AD" },
			{ label: "Норма", value: 20, color: "#2ECC71" },
			{ label: "Превышение", value: 30, color: "#E67E22" },
		],
	},
	{
		day: "ВС",
		bars: [
			{ label: "Факт", value: 35, color: "#8E44AD" },
			{ label: "Норма", value: 25, color: "#2ECC71" },
		],
	},
];

const Reaction = () => {
	return (
		<div className={styles.reaction}>
			<div>Среднее время реакции на обращение</div>
			<CustomBarChart data={data} />
		</div>
	);
};

const WidgetGrid: React.FC<WidgetGridProps> = () => {
	const screens = useBreakpoint();
	const boxesPerRow = screens.xs ? 1 : screens.md ? 4 : 2;

	const [items, setItems] = React.useState<ItemsType>({
		left: [
			{
				position: 0,
				name: "widget 1",
				div: <Reaction />,
			},
			{ position: 1, name: "widget 2" },
			{ position: 2, name: "widget 3" },
			{ position: 3, name: "widget 4" },
			{ position: 4, name: "widget 5" },
		],
		right: [
			{ position: 5, name: "george" },
			{ position: 6, name: "rupert" },
			{ position: 7, name: "alice" },
			{ position: 8, name: "katherine" },
			{ position: 8, name: "pam" },
			{ position: 10, name: "katie" },
		],
	});

	const onChange = (
		sourceId: string,
		sourceIndex: number,
		targetIndex: number,
		targetId?: string
	) => {
		if (targetId) {
			const result = move(
				items[sourceId],
				items[targetId],
				sourceIndex,
				targetIndex
			);
			return setItems({
				...items,
				[sourceId]: result[0],
				[targetId]: result[1],
			});
		}

		const result = swap(items[sourceId], sourceIndex, targetIndex);
		return setItems({
			...items,
			[sourceId]: result,
		});
	};

	return (
		<GridContextProvider onChange={onChange}>
			<div className={styles.container}>
				<GridDropZone
					className={styles.dropzone}
					id="left"
					rowHeight={408}
					boxesPerRow={Math.max(Math.floor(window.innerWidth / 400), 1)}
				>
					{items.left.map((item) => (
						<GridItem key={item.position} className={styles.item}>
							<div className={styles.gridItem}>
								<div className={styles.gridItemContent}>
									{item.div ? item.div : item.name.toUpperCase()}
								</div>
							</div>
						</GridItem>
					))}
				</GridDropZone>
			</div>
		</GridContextProvider>
	);
};

export default WidgetGrid;
