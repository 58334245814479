import React from "react";
import styles from "./styles.module.scss";

type BarData = {
	label: string;
	value: number;
	color: string;
};

type DayData = {
	day: string;
	bars: BarData[];
};

type CustomBarChartProps = {
	data: DayData[];
	maxValue?: number; // Максимальное значение для нормализации
};

const CustomBarChart: React.FC<CustomBarChartProps> = ({ data }) => {
	// Рассчитываем максимальное значение для нормализации высоты, если не указано
	const calculatedMaxValue = Math.max(
		...data.flatMap((day) => day.bars.map((bar) => bar.value))
	);

	return (
		<div className={styles.chartContainer}>
			{data.map((day, dayIndex) => (
				<div key={dayIndex} className={styles.dayColumn}>
					<div className={styles.barStack}>
						{day.bars.map((bar, barIndex) => (
							<div
								key={barIndex}
								className={styles.bar}
								style={{
									height: `${(bar.value / calculatedMaxValue) * 100}%`,
									backgroundColor: bar.color,
								}}
								title={`${bar.label}: ${bar.value}`}
							></div>
						))}
					</div>
					<div className={styles.dayLabel}>{day.day}</div>
				</div>
			))}
		</div>
	);
};

export default CustomBarChart;
