import React, {
	useState,
	useContext,
	createContext,
	useRef,
	useEffect,
} from "react";

import styles from "./styles.module.scss";

const SidebarContext = createContext<{
	isSidebarVisible: boolean;
	toggleSidebar: () => void;
}>(undefined);

const Layout: React.FC<{
	children: React.ReactNode;
	defaultVisible: boolean;
}> = ({ children, defaultVisible }) => {
	const [isSidebarVisible, setIsSidebarVisible] =
		useState<boolean>(defaultVisible);

	const toggleSidebar = () => {
		setIsSidebarVisible(!isSidebarVisible);
	};

	const [isMobile, setIsMobile] = useState<boolean>(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 570);
		};

		if (window.innerWidth > 1024) {
			setIsMobile(false);
		}

		window.addEventListener("resize", handleResize);
		handleResize(); // При первом рендере

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const sidebarRef = useRef<HTMLDivElement | null>(null);

	const sidebarWidth = sidebarRef.current ? sidebarRef.current.offsetWidth : 0;

	return (
		<SidebarContext.Provider value={{ isSidebarVisible, toggleSidebar }}>
			<div className={styles.container}>
				{/* Основной контент */}
				<main
					className={styles.main}
					style={{
						transition: "margin-right 0.3s",
					}}
				>
					<div className={styles.wrapper}>{children}</div>
				</main>

				<div
					ref={sidebarRef}
					className={styles.sidebar}
					style={{
						marginRight: isSidebarVisible ? 0 : `-${sidebarWidth}px`, // Уезжает за границу
						height: "100vh",
						transition: "margin 0.3s ease",
						width: isMobile && isSidebarVisible ? "100%" : "360px", // Плавное движение
					}}
				>
					<div className={styles.sidebarContainer}>
						<span onClick={toggleSidebar}>Sidebar</span>
					</div>
				</div>
			</div>
		</SidebarContext.Provider>
	);
};

export const useSidebar = () => {
	const context = useContext(SidebarContext);
	if (!context) {
		throw new Error("useSidebar must be used within a SidebarProvider");
	}
	return context;
};

export default Layout;
