import io from "socket.io-client";

import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Spin, Button } from "antd";

import Auth from "pages/Login";
import Routes from "pages/Routes";

import { Menu } from "shared/components";
import { useProfile } from "hooks/useContext";

// import { ReactComponent as leftArrow } from 'icons/left-arrow.svg'
// import { ReactComponent as rightArrow } from 'icons/right-arrow.svg'
import { useNotification } from "hooks/useNotification";

// import 'antd/dist/antd.css'
import styles from "./styles.module.scss";

import "react-notifications-component/dist/theme.css";
import useWelcomeMessage from "hooks/useWelcomeMessage";
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const LayoutBar = () => {
	const history = useHistory();
	const { user, loading, logout } = useProfile();

	const { handlerSetShowWelcomeMessage } = useWelcomeMessage();

	const { createNotificationWithCustomTitle } = useNotification();

	const [collapsed, setCollapsed] = useState(true);

	const handleMenuItemClick = ({ key }) => {
		key === history.location.pathname ? history.go(0) : history.push(key);
	};

	const handleClickTicketNotification = useCallback(
		(id) => () => {
			history.push(`/admin/ticket/${id}`);
		},
		[history]
	);

	const handleClickMessageNotification = useCallback(
		(id) => () => {
			history.push(`/admin/ticket/${id}?chat`);
		},
		[history]
	);

	const setTicketNotification = useCallback(
		(message) => {
			for (let i = 0; i < message.length; i++) {
				if (history.location.pathname.split("/")[1] !== "ticket") {
					createNotificationWithCustomTitle(
						message[i].title,
						<TicketNotification
							click={handleClickTicketNotification(message[i]?.data?.id)}
							message={message[i]?.data?.description}
							id={message[i]?.id}
						/>
					);
				}
			}
		},
		[createNotificationWithCustomTitle, handleClickTicketNotification, history]
	);

	const setMessageNotification = useCallback(
		(message) => {
			for (let i = 0; i < message.length; i++) {
				if (history.location.pathname.split("/")[1] !== "ticket") {
					createNotificationWithCustomTitle(
						message[i].title,
						<MessageNotification
							click={handleClickMessageNotification(message[i]?.ticketId)}
							message={message[i]?.data?.message}
							id={message[i]?.id}
						/>
					);
				}
			}
		},
		[createNotificationWithCustomTitle, handleClickMessageNotification, history]
	);

	useEffect(() => {
		if (history.location.pathname.split("/")[1] !== "ticket") {
			const socket = io.connect(process.env.REACT_APP_SOCKET);
			if (user) {
				socket.on("ticket", (message) => {
					setTicketNotification(message);
				});
				socket.on("message", (message) => {
					setMessageNotification(message);
				});

				handlerSetShowWelcomeMessage();
			}
			return () => socket.disconnect();
		}
	}, [history, setTicketNotification, setMessageNotification, user]);

	const handleMoveToProfile = () => {
		history.push("/admin/profile");
	};

	const isAdmin = user?.roles?.find((item) => item.id === 1);
	const isAllow = user?.roles?.find((item) => item.id === 2);

	const handleLogout = () => {
		logout();
	};

	const defaultKey = () => {
		return [history.location.pathname];
	};

	const handleMouseClick = (name) => (e) => {
		if (e.button === 1) {
			window.open(process.env.REACT_APP_URL + name);
		}
	};

	const toggleCollapse = () => {
		setCollapsed(!collapsed);
	};

	if (
		history.location.pathname.split("/")[1] === "ticket" ||
		history.location.pathname.split("/")[1] === "article" ||
		history.location.pathname.split("/")[1] === "reset-password"
	) {
		return <Routes />;
	}

	if (loading) {
		return (
			<div className={styles.spinnerContainer}>
				<Spin className={styles.spinner} />
			</div>
		);
	}
	return (
		<div>
			{user ? (
				<Layout>
					<Layout
						style={{
							minHeight: "100vh",
						}}
					>
						<Routes />
					</Layout>
					<Menu />
				</Layout>
			) : (
				<Auth />
			)}
		</div>
	);
};

export default LayoutBar;

const TicketNotification = ({ message, click }) => {
	const handleClick = () => {
		click();
	};

	return (
		<div className={styles.notiMessageContainer}>
			<p className={styles.notiMessageText}>{message}</p>
			<Button className={styles.notiButton} onClick={handleClick}>
				Открыть
			</Button>
		</div>
	);
};

const MessageNotification = ({ message, click }) => {
	const handleClick = () => {
		click();
	};

	return (
		<div className={styles.notiMessageContainer}>
			<p className={styles.notiMessageText}>{message}</p>
			<Button className={styles.notiButton} onClick={handleClick}>
				Открыть
			</Button>
		</div>
	);
};

{
	/* <Header className={styles.header}>
            <h1 className={styles.logo}>Roby Service Desk</h1>
            <div className={styles.logoutContainer}>
              <UserOutlined
                className={styles.userIcon}
                onClick={handleMoveToProfile}
              />
              <p className={styles.logoutUserName}>{user?.name}</p>
              <LogoutOutlined
                className={styles.logoutIcon}
                onClick={handleLogout}
              />
            </div>
          </Header> */
}

{
	/* <Sider
trigger={null}
collapsible
collapsed={collapsed}
width={200}
style={{ background: '#fff' }}
className={styles.sider}
>
<Menu
  mode='inline'
  defaultSelectedKeys={defaultKey()}
  style={{ borderRight: 0 }}
  onClick={handleMenuItemClick}
>
  isAdmin && (<Menu.Item onMouseDown={handleMouseClick('/admin/requests')} key="/admin/requests">Новые обращения</Menu.Item>)
  <SubMenu
    icon={<ListIcon />}
    key='sub0'
    title='Обращения и инциденты'
  >
    <Menu.Item
      onMouseDown={handleMouseClick('/admin/tickets')}
      key='/admin/tickets'
    >
      Список обращений
    </Menu.Item>
    <Menu.Item
      onMouseDown={handleMouseClick('/admin/incidents')}
      key='/admin/incidents'
    >
      Список инцидентов
    </Menu.Item>
  </SubMenu>
  <Menu.Item
    className={styles.menuItem}
    icon={<ProplemIcon />}
    onMouseDown={handleMouseClick('/admin/problems')}
    key='/admin/problems'
  >
    Системные проблемы
  </Menu.Item>
  <Menu.Item
    className={styles.menuItem}
    icon={<MerchantIcon />}
    onMouseDown={handleMouseClick('/admin/merchants')}
    key='/admin/merchants'
  >
    Список мерчантов
  </Menu.Item>
  {isAdmin && (
    <Menu.Item
      className={styles.menuItem}
      icon={<SoftwareIcon />}
      onMouseDown={handleMouseClick('/admin/softwares')}
      key='/admin/softwares'
    >
      Список ПО
    </Menu.Item>
  )}

  {isAdmin && (
    <Menu.Item
      className={styles.menuItem}
      icon={<TagsIcon />}
      onMouseDown={handleMouseClick('/admin/tags')}
      key='/admin/tags'
    >
      Список Тегов
    </Menu.Item>
  )}
  {isAdmin && (
    <Menu.Item
      className={styles.menuItem}
      icon={<PlacesIcon />}
      onMouseDown={handleMouseClick('/admin/problemplace')}
      key='/admin/problemplace'
    >
      Список проблемных мест
    </Menu.Item>
  )}
  {(isAdmin || isAllow) && (
    <Menu.Item
      icon={<ReportsIcon />}
      onMouseDown={handleMouseClick('/admin/reports')}
      key='/admin/reports'
    >
      Отчеты
    </Menu.Item>
  )}
  {isAdmin && (
    <Menu.Item
      icon={<TelegramIcon />}
      onMouseDown={handleMouseClick(
        '/admin/merchant-bot-message'
      )}
      key='/admin/merchant-bot-message'
    >
      Сообщения мерчанту
    </Menu.Item>
  )}

  {isAdmin && (
    <SubMenu icon={<UsersIcon />} key='sub1' title='Пользователи'>
      <Menu.Item
        onMouseDown={handleMouseClick('/admin/users')}
        key='/admin/users'
      >
        Учетные записи
      </Menu.Item>
      <Menu.Item
        onMouseDown={handleMouseClick('/admin/roles')}
        key='/admin/roles'
      >
        Роли
      </Menu.Item>
    </SubMenu>
  )}

  {isAdmin && (
    <Menu.Item
      className={styles.menuItem}
      icon={<TypeIcon />}
      onMouseDown={handleMouseClick('/admin/types')}
      key='/admin/types'
    >
      Список типов
    </Menu.Item>
  )}

  <Menu.Item
    className={styles.menuItem}
    icon={<ScheduleIcon />}
    onMouseDown={handleMouseClick('/admin/schedule')}
    key='/admin/schedule'
  >
    График работ
  </Menu.Item>

  <Menu.Item
    className={styles.menuItem}
    icon={<FieldTimeOutlinedIcon />}
    onMouseDown={handleMouseClick('/admin/announcement')}
    key='/admin/announcement'
  >
    Типы уведомлений для сотрудников
  </Menu.Item>

  <Menu.Item
    icon={<DateBaseIcon />}
    onClick={handleMouseClick('/knowledge-base')}
    key='/knowledge-base'
  >
    База знаний
  </Menu.Item>
  {collapsed ? (
    <Icon
      component={RightOutlined}
      className={styles.arrowIcon}
      onClick={toggleCollapse}
    />
  ) : (
    <Icon
      component={LeftOutlined}
      className={styles.arrowIcon}
      onClick={toggleCollapse}
    />
  )}
</Menu>
</Sider> */
}
