import React from "react";
import { Button as AtndButton } from "antd";
import styles from "./styles.module.scss";

export type ButtonProps = React.ComponentProps<typeof AtndButton>;

const Button: React.FC<ButtonProps> = ({
	className,
	...restProps
}: ButtonProps) => (
	<AtndButton
		className={[styles.btn, className].join(" ")}
		{...restProps}
		type={"text"}
	/>
);

export default Button;
