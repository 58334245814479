import React from "react";
import { Dropdown as AntdDropdown, MenuProps } from "antd";
import styles from "./styles.module.scss";

import { TooltipPlacement } from "antd/es/tooltip";

export type DropdownProps = React.ComponentProps<typeof AntdDropdown> & {
	header?: React.ReactNode;
	children?: React.ReactNode;
	placement?: TooltipPlacement;
	items: MenuProps["items"];
};

const Dropdown: React.FC<DropdownProps> = ({
	header,
	items,
	children,
	placement = "topRight",
	className,
	...restProps
}) => {
	return (
		<AntdDropdown
			placement={placement}
			overlayClassName={styles.dropdown}
			menu={{ items }}
			dropdownRender={(menu) => (
				<div className={styles.blockInnerMenu}>
					<div>{header}</div>
					{menu}
				</div>
			)}
			{...restProps}
		>
			{children}
		</AntdDropdown>
	);
};

export default Dropdown;
