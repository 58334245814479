import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import axios from '../utils/axios'
import md5 from 'md5'

const AppContext = createContext({
  // history: {},
  storage: {},
  profile: {
    user: null,
    login: () => {
      /* empty */
    },
    logout: () => {
      /* empty */
    },
    setUser: () => {
      /* empty */
    },
    loading: true
  },
  robyFetching: {
    // fetchJson: () => {},
    robyData: null
  }
})

const LS_LOGGED_KEY = 'loggedIn'

export const ProvideAppContext = ({ children }) => {
  const storage = useProvideStorage()
  const profile = useProvideProfile()
  const robyFetching = useRobyConnection()
  // const history = useProvideHistory()
  return (
    <AppContext.Provider
      value={{ /* history, */ storage, robyFetching, profile }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useStorage = () => {
  const { storage } = useContext(AppContext)
  return storage
}

export const useProfile = () => {
  const { profile } = useContext(AppContext)
  const admin = profile.user?.roles?.find((item) => item.id === 1)
  return { ...profile, isAdmin: !!admin }
}

export const useRoby = () => {
  const { robyFetching } = useContext(AppContext)
  return robyFetching
}

const useRobyConnection = () => {
  const Config = {
    SERVICE_KEY: 'F57CC4CD-544D-466E-9745-D2DF7DD90E58',
    API_URL: 'https://roby.market/api'
  }
  const ResponseCode = {
    OK: 0, //все хорошо
    NOT_AUTH: 101, //Пользователь не авторизован
    NEED_AUTH: 201 //Сессия умерла
  }

  // eslint-disable-next-line no-unused-vars
  const [robyData, setRobyData] = useState({
    email: '',
    phone: process.env.REACT_APP_ROBY_PHONE,
    pass: md5(
      process.env.REACT_APP_ROBY_PASS + Config.SERVICE_KEY
    ).toUpperCase(),
    SMSCode: ''
  })

  const getHashRequest = (requestId) => {
    const sessionId = localStorage.getItem('sessionId')
    return md5(requestId + sessionId + Config.SERVICE_KEY).toUpperCase()
  }

  const getHashPass = (pass) => {
    return md5(pass + Config.SERVICE_KEY).toUpperCase()
  }

  const getMessage = (requestId, data = {}) => {
    const hash = getHashRequest(requestId)
    const sessionId = localStorage.getItem('sessionId')
    const message = {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        requestId,
        hash,
        sessionId,
        data
      }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    }
    return message
  }

  /**
   * Отправка подготовленного пакета для API
   * @param requestId - идентификатор запроса
   * @param data - Object содержимое посылки
   * @returns {Promise<Response>}
   */
  const fetchJson = async (requestId, data = {}) => {
    try {
      const response = await fetch(Config.API_URL, getMessage(requestId, data))

      if (response.status < 200 || response.status >= 300) {
        return Promise.reject(new Error(response.statusText))
      }

      const responseData = await response.json()
      const { code = -1, info = 'Неизвестная ошибка' } = responseData
      //если ответ успешен
      if (code === ResponseCode.OK) {
        return responseData
      }

      if (code === ResponseCode.NEED_AUTH) {
        await login()
        const newResponse = await fetch(Config.API_URL, getMessage(requestId))
        return newResponse.json()
      }

      let statusText = info
      if (code === ResponseCode.NOT_AUTH) {
        statusText = 'Неверный логин/ пароль'
      }

      console.info('fetchJson', statusText, code)
      return Promise.reject(new Error(statusText, code))
    } catch (e) {
      console.info('fetchJson catch', 'rm.api.error')
      return Promise.reject(new Error('rm.api.error', 404))
    }
  }

  const login = async () => {
    try {
      const res = await fetchJson(1, robyData)
      if (res.code === ResponseCode.OK) {
        localStorage.setItem('sessionId', res.data?.sessionId)
      }
    } catch (e) {
      console.log(e)
    }
  }
  return { getHashPass, fetchJson, robyData }
}

const useProvideProfile = () => {
  const [user, _setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [notifications, setNotifications] = useState([])

  const setUser = (user) => {
    if (user) user.isAdmin = user.roles.findIndex((r) => r.id === 1) !== -1
    _setUser(user)
  }

  const login = useCallback(async (data) => {
    localStorage.setItem(LS_LOGGED_KEY, true)
    setUser(data.user)
  }, [])

  const logout = useCallback(async () => {
    // TODO: delete later
    localStorage.setItem('token', '')
    localStorage.removeItem('userHasSeenWelcomeMessage')

    localStorage.setItem(LS_LOGGED_KEY, false)

    try {
      await axios.get('/auth/logout')
    } catch {
      /** */
    }

    setUser(null)
  }, [])

  const loadUser = useCallback(async () => {
    try {
      const res = await axios.get('/profile')
      setUser(res.data)
    } catch {
      logout()
    }
  }, [logout])

  const updateUser = useCallback((data) => {
    setUser(data)
  }, [])

  const changeProfileEmail = (email) => {
    setUser({ ...user, email })
  }

  const changeProfileChatId = (chatId) => {
    setUser({ ...user, chatId })
  }

  const load = useCallback(async () => {
    try {
      setLoading(true)

      const loggedIn = localStorage.getItem(LS_LOGGED_KEY) === 'true'
      if (loggedIn) {
        await loadUser()
      }
    } catch {
      /* empty */
    } finally {
      setLoading(false)
    }
  }, [loadUser])

  useEffect(() => {
    load()
  }, [load])

  return {
    user,
    login,
    logout,
    setUser,
    loading,
    updateUser,
    changeProfileEmail,
    changeProfileChatId,
    notifications
  }
}

const useProvideStorage = () => {
  const { fetchJson } = useRoby()

  const [staff, setStaff] = useState([])
  const [channels, setChannels] = useState([])
  const [problemPlaces, setProblemPlaces] = useState([])
  const [merchants, setMerchants] = useState([])
  const [softwares, setSoftwares] = useState([])
  const [problemUsers, setProblemUsers] = useState([])
  const [robyKiosks, setRobyKiosks] = useState([])

  const [employees, setEmployees] = useState([])
  const [userStories, setUserStories] = useState([])

  const loadStaff = useCallback(async () => {
    if (!staff.length) {
      try {
        const res = await axios.get('/user/staff')
        setStaff(res.data)
      } catch {
        /*empty */
      }
    }
  }, [staff])

  const loadRobyKiosks = useCallback(async () => {
    if (!robyKiosks.length) {
      try {
        const res = await fetchJson(26)
        setRobyKiosks(res.data)
      } catch {
        /*empty */
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [robyKiosks])

  const loadChannels = useCallback(async () => {
    if (!channels.length) {
      try {
        const res = await axios.get('/channel')
        setChannels(res.data)
      } catch {
        /*empty */
      }
    }
  }, [channels])

  const loadProblemPlaces = useCallback(async () => {
    if (!problemPlaces.length) {
      try {
        const res = await axios.get('/problemplace')
        setProblemPlaces(res.data)
      } catch {
        /*empty */
      }
    }
  }, [problemPlaces])

  const loadMerchants = useCallback(async () => {
    if (!merchants.length) {
      try {
        const res = await axios.get('/merchant')
        setMerchants(res.data)
      } catch {
        /*empty */
      }
    }
  }, [merchants])

  const loadSoftwares = useCallback(async () => {
    if (!softwares.length) {
      try {
        const res = await axios.get('/software')
        setSoftwares(res.data)
      } catch {
        /*empty */
      }
    }
  }, [softwares])

  const loadProblemUsers = useCallback(async () => {
    if (!problemUsers.length) {
      try {
        const res = await axios.get('/problems/users')
        setProblemUsers(res.data)
      } catch {
        /*empty */
      }
    }
  }, [])

  const getListEmployees = useCallback(async () => {
    if (!employees.length) {
      try {
        const res = await axios.get('/azuro/employees')
        setEmployees(res.data)
      } catch {
        /*empty */
      }
    }
  }, [])

  const getListUserStories = useCallback(async () => {
    if (!userStories.length) {
      try {
        const res = await axios.get('/azuro/stories')
        setUserStories(res.data)
      } catch {
        /*empty */
      }
    }
  }, [])

  return {
    staff,
    loadStaff,

    channels,
    loadChannels,

    problemPlaces,
    loadProblemPlaces,

    merchants,
    loadMerchants,

    softwares,
    loadSoftwares,

    problemUsers,
    loadProblemUsers,

    robyKiosks,
    loadRobyKiosks,

    employees,
    getListEmployees,

    userStories,
    getListUserStories
  }
}
