import React from "react";
import { useSidebar } from "shared/components/Layout";
import { useProfile } from "hooks/useContext";
// import { Modal, Switch, Button, Input, Row, message } from "antd";
import styles from "./styles.module.scss";
import { LinkOutlined } from "@ant-design/icons";
import { ExcludeSvg } from "shared/icons/menu";
import WidgetGrid from "shared/components/WidgetGrid";
import Button from "shared/components/Button";
type UserType = {
	name: string;
	roles: { name: string }[];
} | null;

const Dashboard: React.FC = () => {
	const { toggleSidebar, isSidebarVisible } = useSidebar();

	const { user } = useProfile();

	const User: { user: UserType } = useProfile();
	const firstName = React.useMemo(
		() => (User?.user?.name || "").split(" ")[0],
		[user]
	);

	const [loading, setLoading] = React.useState(false);

	return (
		<div>
			<header className={styles.header}>
				<div>
					<div className={styles.gretting}>
						<div className={styles.hi}>{`Привет, `}</div>
						<div className={styles.name}>{firstName}</div>
					</div>
				</div>
				<div>
					<Button
						style={{ marginRight: "5px" }}
						loading={loading}
						onClick={() => {
							setLoading(true);
							setTimeout(() => setLoading(false), 2000);
						}}
					>
						Обновить
					</Button>
					<Button
						className={isSidebarVisible ? styles.activeSidebar : ""}
						icon={<ExcludeSvg />}
						onClick={toggleSidebar}
					/>
				</div>
			</header>
			<WidgetGrid />
		</div>
	);
};

export default Dashboard;
