import React from "react";
import { Route, RouteProps } from "react-router-dom";
import LayoutWrapper from "shared/components/Layout"; // Путь к LayoutWrapper

interface CustomRouteProps extends RouteProps {
	defaultVisible?: boolean;
	component: React.ComponentType<any>;
}

const RouteNewDesign: React.FC<CustomRouteProps> = ({
	component: Component,
	defaultVisible = false,
	...rest
}) => {
	return (
		<Route
			{...rest}
			render={(props) => (
				<LayoutWrapper defaultVisible={defaultVisible}>
					<Component {...props} />
				</LayoutWrapper>
			)}
		/>
	);
};

export default RouteNewDesign;
